import React from 'react';
import { Link } from "react-router-dom"
import CV from '../../Assets/CV_Silviu_Ilie.pdf'
import { ImBlog } from 'react-icons/im'
import { MdOutlineDescription } from 'react-icons/md'
import { RiCodeSSlashFill } from 'react-icons/ri'
import { AiFillLock } from 'react-icons/ai'

export default class CTA extends React.Component {
    render() {
        return (
            <div className="cta">
                <a href={CV} download className='btn'>
                    <div className="cta__contents">
                        <MdOutlineDescription className='cta__icon'/>
                        Resume
                    </div>
                </a>
                <a href="#portfolio" className='btn btn-primary'>
                    <div className="cta__contents">
                        <RiCodeSSlashFill className='cta__icon' />
                        MyWork
                    </div>
                </a>
                <Link to="" className="btn btn-primary">
                    <div className="cta__contents">
                        <ImBlog className='cta__icon' />
                        Blog
                    </div>
                </Link>
            </div>
        );
    }
}
//<a href={CV} download className='btn cta__locked'>
//    <div className="cta__content-stack">
//        <div className="cta__contents cta__content-layer1">
//            <ImBlog className='cta__icon' />
//            Blog
//        </div>
//        <div className="cta__content-layer2 cta__icont-locked-icon">
//            <AiFillLock  />
//        </div>
//    </div>
//</a>