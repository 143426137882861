export const vertexShaderSource = `
    attribute vec4 a_position;
    void main() {
        gl_Position = a_position;
    }
`;

//fragment shaders don't have default precission -> need to pick one (mediump)
export const fragmentShaderSource = `
    precision highp float;

    uniform vec2 iResolution;
    uniform vec2 iMouse;
    uniform float iTime;

    #define ANIM_INTERVAL_STANDBY 1.0
    #define ANIM_MIDDLE_LINE_VERTICAL 1.2
    #define ANIM_HOLD_POS 1.7
    #define ANIM_CHEVRON 1.9


    float sdLine(vec2 po, vec2 a, vec2 bo, float r) 
    {
        vec2 p = po;
        vec2 b = bo;
	    p -= a;
	    b -= a;
	    float d = length(p - b * clamp (dot (p, b) / dot (b, b), 0.0, 1.0));
	    return smoothstep(r + 0.01, r, d);
    }

    float smin(in float a, in float b, float k)
    {
        float h = max(k-abs(a-b), 0.0);
        return min(a,b) - h*h/(k*4.0);
    }

    float lerp(in float begin, in float dest, in float percentage)
    {
        return begin + (dest - begin) * percentage;
    }

    float sdLogo(vec2 uv)
    {
        vec2 center = vec2(0.0,-0.5);
        float l = 0.1; // half length of the middle line
        float radius = 0.005;
        float diag = l * 1.41;
        float halfDiag = l * 0.7;
    
        float offset = 0.05;
            
        if(iTime < ANIM_INTERVAL_STANDBY)
        {
            float middleLine = sdLine(uv, center - vec2(halfDiag, halfDiag), center + vec2(halfDiag, halfDiag), radius);
    
            vec2 leftChevronPos = center - vec2(halfDiag + offset + l * 0.5, 0.0);
            float leftChevronUpperLine = sdLine(uv, leftChevronPos, leftChevronPos + vec2(halfDiag, halfDiag), radius);
            float leftChevronDownLine = sdLine(uv, leftChevronPos, leftChevronPos + vec2(halfDiag, -halfDiag), radius);
            float leftChevron = max(leftChevronUpperLine, leftChevronDownLine);

            vec2 rightChevronPos = center + vec2(halfDiag + offset + l * 0.5, 0.0);
            float rightChevronUpperLine = sdLine(uv, rightChevronPos, rightChevronPos - vec2(halfDiag, halfDiag), radius);
            float rightChevronDownLine = sdLine(uv, rightChevronPos, rightChevronPos - vec2(halfDiag, -halfDiag), radius);
            float rightChevron = max(rightChevronUpperLine, rightChevronDownLine);
            return middleLine + leftChevron + rightChevron;//min(middleLine, leftChevronUpperLine);
        }
        else if(iTime < ANIM_MIDDLE_LINE_VERTICAL)
        {
            float percentage = (iTime - ANIM_INTERVAL_STANDBY) / (ANIM_MIDDLE_LINE_VERTICAL - ANIM_INTERVAL_STANDBY);
            float middleLinePointValueX = lerp(l * 0.5, 0.0, percentage);
            float middleLinePointValueY = lerp(halfDiag, diag, percentage);
            float middleLine = sdLine(uv, center - vec2(middleLinePointValueX, middleLinePointValueY), 
                                        center + vec2(middleLinePointValueX, middleLinePointValueY), radius);
    
            vec2 chevronDeltas = vec2(offset + middleLinePointValueX + halfDiag, 0.0);
            vec2 leftChevronPos = center - chevronDeltas;
            float leftChevronUpperLine = sdLine(uv, leftChevronPos, leftChevronPos + vec2(halfDiag, halfDiag), radius);
            float leftChevronDownLine = sdLine(uv, leftChevronPos, leftChevronPos + vec2(halfDiag, -halfDiag), radius);
            float leftChevron = max(leftChevronUpperLine, leftChevronDownLine);

            vec2 rightChevronPos = center + chevronDeltas;
            float rightChevronUpperLine = sdLine(uv, rightChevronPos, rightChevronPos - vec2(halfDiag, halfDiag), radius);
            float rightChevronDownLine = sdLine(uv, rightChevronPos, rightChevronPos - vec2(halfDiag, -halfDiag), radius);
            float rightChevron = max(rightChevronUpperLine, rightChevronDownLine);
            return middleLine + leftChevron + rightChevron;
        }
        else if(iTime < ANIM_HOLD_POS)
        {
            float middleLine = sdLine(uv, center - vec2(0, diag), center + vec2(0, diag), radius);
        
            vec2 chevronDeltas = vec2(offset + halfDiag, 0.0);
            vec2 leftChevronPos = center - chevronDeltas;
            float leftChevronUpperLine = sdLine(uv, leftChevronPos, leftChevronPos + vec2(halfDiag, halfDiag), radius);
            float leftChevronDownLine = sdLine(uv, leftChevronPos, leftChevronPos + vec2(halfDiag, -halfDiag), radius);
            float leftChevron = max(leftChevronUpperLine, leftChevronDownLine);

            vec2 rightChevronPos = center + chevronDeltas;
            float rightChevronUpperLine = sdLine(uv, rightChevronPos, rightChevronPos - vec2(halfDiag, halfDiag), radius);
            float rightChevronDownLine = sdLine(uv, rightChevronPos, rightChevronPos - vec2(halfDiag, -halfDiag), radius);
            float rightChevron = max(rightChevronUpperLine, rightChevronDownLine);
            return middleLine + leftChevron + rightChevron;
        }
        else if(iTime < ANIM_CHEVRON)
        {
            float middleLine = sdLine(uv, center - vec2(0, diag), center + vec2(0, diag), radius);
        
            float percentage = (iTime - ANIM_HOLD_POS) / (ANIM_CHEVRON - ANIM_HOLD_POS);
            float chevronPosY = -lerp(0.0, halfDiag, percentage);
        
            vec2 chevronDeltas = vec2(offset + halfDiag, chevronPosY);
            vec2 leftChevronPos = center - chevronDeltas;
            float leftChevronUpperLine = sdLine(uv, leftChevronPos, leftChevronPos + vec2(halfDiag, halfDiag), radius);
            float leftChevronDownLine = sdLine(uv, leftChevronPos, leftChevronPos + vec2(halfDiag, -halfDiag), radius);
            float leftChevron = max(leftChevronUpperLine, leftChevronDownLine);

            vec2 rightChevronPos = center + chevronDeltas;
            float rightChevronUpperLine = sdLine(uv, rightChevronPos, rightChevronPos - vec2(halfDiag, halfDiag), radius);
            float rightChevronDownLine = sdLine(uv, rightChevronPos, rightChevronPos - vec2(halfDiag, -halfDiag), radius);
            float rightChevron = max(rightChevronUpperLine, rightChevronDownLine);
            return middleLine + leftChevron + rightChevron;
        }
        else
        {
            float middleLine = sdLine(uv, center - vec2(0, diag), center + vec2(0, diag), radius);
        
            vec2 chevronDeltas = vec2(offset + halfDiag, -halfDiag);
            vec2 leftChevronPos = center - chevronDeltas;
            float leftChevronUpperLine = sdLine(uv, leftChevronPos, leftChevronPos + vec2(halfDiag, halfDiag), radius);
            float leftChevronDownLine = sdLine(uv, leftChevronPos, leftChevronPos + vec2(halfDiag, -halfDiag), radius);
            float leftChevron = max(leftChevronUpperLine, leftChevronDownLine);

            vec2 rightChevronPos = center + chevronDeltas;
            float rightChevronUpperLine = sdLine(uv, rightChevronPos, rightChevronPos - vec2(halfDiag, halfDiag), radius);
            float rightChevronDownLine = sdLine(uv, rightChevronPos, rightChevronPos - vec2(halfDiag, -halfDiag), radius);
            float rightChevron = max(rightChevronUpperLine, rightChevronDownLine);
            return middleLine + leftChevron + rightChevron;
        }
    }

    float grid(vec2 uv, float battery)
    {
        vec2 size = vec2(uv.y, uv.y * uv.y) * 0.01;
        uv += vec2(0.0, iTime * 4.0 * (battery + 0.05));
        uv = abs(fract(uv) - 0.5);
 	    vec2 lines = smoothstep(size, vec2(0.0), uv);
 	    lines += smoothstep(size * 5.0, vec2(0.0), uv) * 0.4 * battery;
        return clamp(lines.x + lines.y, 0.0, 3.0);
    }

    void mainImage( out vec4 fragColor, in vec2 fragCoord )
    {
        vec2 uv = (2.0 * fragCoord.xy - iResolution.xy)/iResolution.y;
        float battery = 1.0;
        //background color
        vec3 col = vec3(0.01176, 0.0745, 0.16);
        vec3 gridBlue = vec3(0.04, 0.66, 0.78);
        vec3 gridRed = vec3(0.93, 0.0, 0.09);
    
        float lastIntervalValue = -0.98;
        float fadeIntervalValue = -0.8;
        float gridIntervalValue = -0.7;
        float startGridPosition = abs(uv.y - gridIntervalValue);
        float fog = smoothstep(0.1, -0.02, startGridPosition);
    
        col += sdLogo(uv) * gridRed;
        if(uv.y < lastIntervalValue)
        {
        
        }
        else if(uv.y < fadeIntervalValue )
        {
            float intervalLength = fadeIntervalValue - lastIntervalValue;
            float uvYValueInInterval = (fadeIntervalValue - uv.y) / intervalLength;
            uv.y = 1.5 / (startGridPosition + 0.05);
            uv.x *= uv.y * 1.0;
            float gridVal = grid(uv, battery);
            vec3 gridCol = mix(col, vec3(0.04, 0.66, 0.78), gridVal);
            col = (1.0 - uvYValueInInterval) * gridCol + uvYValueInInterval * col;
        }
        else if (uv.y < gridIntervalValue)
        {
            uv.y = 1.5 / (startGridPosition + 0.05);
            uv.x *= uv.y * 1.0;
            float gridVal = grid(uv, battery);
            col = mix(col, vec3(0.04, 0.66, 0.78), gridVal);
        }
        col += fog * fog * fog;
        col = mix(vec3(col.r, col.r, col.r) * 0.5, col, battery * 0.8);

        // Output to screen
        fragColor = vec4(col,1.0);
    }

    void main() {
        mainImage(gl_FragColor, gl_FragCoord.xy);
    }

`;

export function createShader(gl, type, source) {
    var shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    var success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
    if (success) {
        return shader;
    }

    gl.deleteShader(shader);
}