import React from 'react';
import './Header.css';
import {BsLinkedin} from 'react-icons/bs'
import {BsYoutube} from 'react-icons/bs'

export default class HeaderSocials extends React.Component {
    render() {
        return (
            <header>
                <div className="header__socials">
                    <a href="https://www.linkedin.com/in/silviu-ilie" target="_blank" rel="noreferrer"><BsLinkedin/></a>
                    <a href="https://youtube.com" target="_blank" rel="noreferrer"><BsYoutube/></a>
                </div>
            </header>
        );
    }
}