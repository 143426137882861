import React from 'react';
import './About.css';
import PortfolioProfile from '../../Assets/PortfolioProfile.png'
import {FaAward} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'

export default class About extends React.Component {
    render() {
        return (
            <section id='about'>
                <h5>Get to know</h5>
                <h2>About me</h2>

                <div className="container about__container">
                    <div className="about__me">
                        <div className="about__me-image">
                            <img src={PortfolioProfile} alt="About img"></img>
                        </div>
                    </div>

                    <div className="about__content">
                        <div className="about__cards">
                            <article className="about__card">
                                <FaAward className='about__icon'/>
                                <h5>Experience</h5>
                                <small>5+ Years</small>
                            </article>
                            
                            <article className="about__card">
                                <VscFolderLibrary className='about__icon' />
                                <h5>Shipped Projects</h5>
                                <small>5+</small>
                            </article>
                        </div>
                        
                        <p>
                            <big style={{'color':'var(--color-blue)'}}>Hey There!</big>
                            <br></br>
                            I am Silviu, a dedicated programmer and an enthusiastic game developer from Romania.
                            I am eager to learn more and take on new challenges, create stable and performant software and
                            bring unforgettable worlds to life.
                        </p>
                        <div className="about__content-cta">
                            <a href="#contact" className='btn btn-primary'>Let's Talk</a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}