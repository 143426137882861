import React, { Component } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Link } from "react-router-dom"

import { vscDarkPlus as CodeStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './BlogPages.css'
import NavBlogs from '../../Components/Nav/NavBlogs.js'
import Contact from '../../Components/Contact/Contact.js'
import Footer from '../../Components/Footer/Footer.js'
import { BlogEntries } from '../../Components/BlogsOverview/BlogEntries.js'

export default class UnrealMovementP2 extends Component {
    render() {
        const playerClassExample =
            `class Player\n{\n// Constructors and other code\nprivate:\n\tfloat m_health;\n\tfloat m_mana;\n\tfloat m_power;\n\tVector3* m_meshVertices;\n\tAABB m_meshBounds;\n\tVector3 m_position;\n\tVector3 m_rotation;\n\tVector3 m_velocity\n\t\n};`;

        const statsClassExample =
            `class StatsComponent\n{\n// Constructors and other code\nprivate:\n\tfloat m_health;\n\tfloat m_mana;\n\tfloat m_power;\n};`;

        const playersClassComponentsExample =
            `class Player\n{\n// Constructors and other code\nprivate:\n\tMeshComponent m_meshComponent;\n\tStatsComponent m_statsComponent;\n\tInputComponent m_inputComponent;\n\tMovementComponent m_movementComponent;\n};`;

        return (
            <div>
                <section>
                    <h2>{BlogEntries[1].title}</h2>
                    <div className="content-background container">
                        <h3>1. Introduction</h3>
                        <p>In the <Link to={BlogEntries[0].link}>previous article</Link>, we had discussed about Actor and Scene Components.
                        Now, we will use that knowledge in order to understant the architecture of the code for Movement Components in Unreal Engine.
                        </p>
                        <br />
                        <h3>2. Movement Component Diagram</h3>
                        <p>
                            One of the core concepts of OOP is <b>composition</b>, which is the idea of creating a complex class using smaller ones.
                        </p>
                        <br />
                        <h3>3. Diagram Interpretation</h3>
                        <br />
                        <br />
                        <h2></h2>
                        <p>In the <Link to={BlogEntries[1].link}>next blog entry</Link>, we will start looking over Movement Components architecture.</p>

                    </div>
                </section>
                <NavBlogs />
                <Contact />
                <Footer />
            </div >
        );
    }
};