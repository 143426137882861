import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Pages/Home.js'
import Blogs from './Pages/Blogs.js'
import { BlogEntries } from './Components/BlogsOverview/BlogEntries.js'

export default class App extends Component {
    static displayName = App.name;

    render() {
        let blogs = BlogEntries.map((blogEntry, index) => {
            return <Route key={index} path={blogEntry.link} element={blogEntry.element} />;
        });

        return (
            <BrowserRouter>
                <Routes>
                <Route index element={<Home />} />
                </Routes>
            </BrowserRouter>
        );
    }
}
