import React from 'react';
import './Experience.css';
import { BsPatchCheckFill } from 'react-icons/bs'

const programmingLanguages = [
    {skillName : "C++"},
    {skillName : "Unreal Engine"},
    {skillName : "C#"},
    {skillName : "Unity Engine"},
    {skillName : "HLSL"},
    {skillName : "GLSL"},
    {skillName : "Python"},
    {skillName : "Javascript"}
];

const toolsNFrameworks = [
    {skillName : "Git"},
    {skillName : "Perforce"},
    {skillName : "SVN"},
    {skillName : "QT"},
    {skillName : ".Net/Asp.Net Core"},
    {skillName : "DirectX"},
    {skillName : "OpenGL"},
    {skillName : "WebGL"}
];

export default class Experience extends React.Component {
    static renderSkills(skills) {
        return (
            <div className="experience__content">
                {skills.map((skill,id) =>
                    <article key={id} className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon" />
                        <div>
                            <h4>{skill.skillName}</h4>
                            {/*<small className="text-light">Experienced</small>*/}
                        </div>
                    </article>
                )}
            </div>
        );
    }

    render() {
        let programmingLanguagesUI = Experience.renderSkills(programmingLanguages);
        let toolsAndFrameworksUI = Experience.renderSkills(toolsNFrameworks);

        return (
            <section id='experience'>
                <h5>Skills I Have</h5>
                <h2>My Experience</h2>

                <div className="container experience__container">
                    <div>
                        <h3>Programming Languages <br></br>and Game Engines</h3>
                        {programmingLanguagesUI}
                    </div>

                    <div>
                        <h3>Frameworks <br></br>and Tools</h3>
                        {toolsAndFrameworksUI}
                    </div>
                </div>
            </section>
        );
    }
}