import React from 'react';
import './Services.css';
import { BiCheck } from 'react-icons/bi'

const allServices = [
    {
        servicesTypeName : "Software Development",
        services : [
            { serviceDescription : "C++ Software Development" },
            { serviceDescription : "C# Software Development" },
            { serviceDescription : ".Net, ASP .Net Core Development" }
        ]
    },
    {
        servicesTypeName : "Game Development", 
        services : [ 
            { serviceDescription : "Unreal Engine Game Development" },
            { serviceDescription : "Unity Engine Game Development" },
            { serviceDescription : "Core Game Engine Development" }
        ]
    }
];

export default class Services extends React.Component {

    constructor(props) {
        super(props);
    }

    static renderServices(allServices) {
        return (
            <div className="container services__container">
                {allServices.map((servicesContainer, containerId) =>
                    <article key={containerId} className="services">

                        <div className="service__head">
                            <h3>{servicesContainer.servicesTypeName}</h3>
                        </div>

                        <ul className="service__list">
                            {servicesContainer.services.map((service, id) =>
                                <li key={id}>
                                    <BiCheck className="service__list-icon" />
                                    <p>{service.serviceDescription}</p>
                                </li>
                            )}
                        </ul>

                    </article>
                )}
            </div>
        );
    }

    render() {
        let content = Services.renderServices(allServices);
        return (
            <section id='services'>
                <h5>What I Offer</h5>
                <h2>Services</h2>
                {content}
            </section>
        );
    }
}