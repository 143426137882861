import React from 'react';
import './Card.css'
import { Link } from "react-router-dom"

export default class BlogCard extends React.Component {
    render() {
        return (
            <Link to={this.props.link} className="container card-background card_layout">
                <img className="card-img" src={this.props.blogImg} alt="About img"></img>
                <div className="card-text">
                    <h2>{ this.props.title }</h2>
                    <p className="card-text-description">{ this.props.description }</p>
                </div>
            </Link>
        );
    }
}