import LogoSI from '../../Assets/LogoSI.svg'
import UnrealMovementP1 from '../../Pages/BlogPages/UnrealMovementP1.js';
import UnrealMovementP2 from '../../Pages/BlogPages/UnrealMovementP2.js';

export const BlogEntries = [
    {
        img: LogoSI,
        title: "Understanding Movement in Unreal Engine : Components",
        description: "In this blog post, we will look over Actor Components and Scene Components in Unreal Engine",
        link: "/Blogs/UnrealMovementP1",
        element: <UnrealMovementP1 />
    },
    {
        img: LogoSI,
        title: "Understanding Movement in Unreal Engine : Code Architecture",
        description: "In this blog post, we will look over Movement Components code architecture and we will try to understand the bigger picture",
        link: "/Blogs/UnrealMovementP2",
        element: <UnrealMovementP2/>
    },
];
