import React from 'react';
import './BlogsOverview.css'
import BlogCard from '../Card/BlogCard.js'
import { BlogEntries } from './BlogEntries.js'

export default class BlogsOverview extends React.Component {
    render() {
        let blogs = BlogEntries.map((blogEntry, index) => {
            return <li key={index}>
                <BlogCard blogImg={blogEntry.img} title={blogEntry.title} description={blogEntry.description} link={blogEntry.link} />
            </li>;
        });
        return (
            <section>
                <h5>Tutorials and in-depth overviews</h5>
                <h2>Blog Posts</h2>
                <ul>
                    {blogs}
                </ul>
            </section>
        );
    }
}