import React from 'react';
import './Nav.css';
import {AiOutlineHome} from 'react-icons/ai'
import { BiMessageSquareDetail } from 'react-icons/bi'
import { Link } from "react-router-dom"

export default class Nav extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeNav: "#"
        };
    }

    setActiveNav(value) {
        this.setState({ activeNav: value });
    }

    render() {
        return (
            <nav>
                <Link to="/"><AiOutlineHome /></Link>
                <a href="#contact" onClick={() => this.setActiveNav("#contact")} className={this.state.activeNav === "#contact" ? 'active' : ''}><BiMessageSquareDetail /></a>
            </nav>
        );
    }
}