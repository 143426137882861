import React from 'react';
import './Contact.css';
import { MdOutlineEmail } from 'react-icons/md'

function sendEmailSMTP(e) {
    var name = document.getElementById("form-name").value;
    var email = document.getElementById("form-email").value;
    var body = document.getElementById("form-body").value;
    var subject = "Sent from site by " + name;

    window.Email.send({
        Host: "smtp.titan.email",
        Username: "<Mailtrap username>",
        Password: "<Mailtrap password>",
        To: "contact@silviuilie.com",
        From: email,
        Subject: subject ,
        Body: body
    }).then(
        message => alert(message)
    );
}

function sendMailTo(e) {
    e.preventDefault();
    var name = document.getElementById("form-name").value;
    var email = document.getElementById("form-email").value;
    var body = "Name : " + name + "%0D%0AEmail : " + email + "%0D%0A%0D%0A" + document.getElementById("form-body").value;
    var subject = "Sent from site by " + name;

    window.location.href = "mailto:contact@silviuilie.com?body="+body+"&subject="+subject;
}

export default class Contact extends React.Component {
    render() {
        return (
            <section id='contact'>
                <h5>Get In Touch</h5>
                <h2>Contact Me</h2>
                <div className="container contact__container">
                    <div className="contact__options">
                        <article className="contact__option">
                            <MdOutlineEmail className="contact__option-icon" />
                            <h4>Email</h4>
                            <h5>contact@silviuilie.com</h5>
                            <a href="mailto:contact@silviuilie.com" target="_blank" rel="noreferrer">Send a message</a>
                        </article>
                    </div>
                    <form onSubmit={sendMailTo}>
                        <input id="form-name" type="text" placeholder="Your Full Name" required />
                        <input id="form-email" type="email"  name="form-email" placeholder="Your Email" required />
                        <textarea id="form-body" type="text" rows="4" placeholder="Your Message" required />

                        <button type="submit" id="send-button" className="btn btn-primary">Send Message</button>
                    </form>
                </div>
            </section>
        );
    }
}



//action="mailto:contact@silviuilie.com" method="post">