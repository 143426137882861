export function createProgram(gl, vs, fs) {
    var program = gl.createProgram();
    gl.attachShader(program, vs);
    gl.attachShader(program, fs);
    gl.linkProgram(program);

    var success = gl.getProgramParameter(program, gl.LINK_STATUS);
    if (success) {
        return program;
    }

    gl.deleteProgram(program);
}

export function setMousePosition(e, canvas, mousePosX, mousePosY) {
    const rect = canvas.getBoundingClientRect();
    mousePosX = e.clientX - rect.left;
    mousePosY = rect.height - (e.clientY - rect.top) - 1;
}