import React from 'react';
import './Portfolio.css';
import test from "../../Assets/gears-pexels.jpg"

const portfolio = [
    {
        video: "https://www.youtube.com/embed/9b9Fr0qMnCU",
        noVideoImg: "",
        title: "Gangsta New York",
        position: "Software Engineer",
        company: "Gameloft",
        platforms: "(PC)",
        topics: [
            "- Worked on car spawn and respawn",
            "- Worked on traffic system for cars and drones",
            "- Worked on some quests of the game",
            "- Worked on a component responsibble for applying effects on objects spawn and destroy",
            "- Worked on loading screens"
        ]
    },
    {
        video: "https://www.youtube.com/embed/tmp8p8ymkTc",
        noVideoImg: "",
        title: "Sniper Champions",
        position: "Software Engineer",
        company: "Gameloft",
        platforms: "(Android | iOS)",
        topics: [
            "- Worked on battle pass",
            "- Worked on a lottery wheel that gave rewards to the players",
            "- Worked on 3D weapon preview inside equipment tab",
            "- Worked on game start and game end",
            "- Worked on a simulator of the outcome of playing the games",
            "- Worked on menu popups(Review Game, Quit Game, Set Player Name, Pending Ad)",
            "- Worked on implementing SDKs for ads and tracking"
        ]
    },
    {
        video: "https://www.youtube.com/embed/k5ZA3_rkqeg",
        noVideoImg: "",
        title: "WSOP",
        position: "Software Engineer",
        company: "Playtika",
        platforms: "(Android | iOS)",
        topics: [
            "- Worked on a stamping system that rewards players after they make a purchase",
            "- Worked on a lottery that was deciding the outcome of poker tournaments",
            "- Worked on a coupon system that was giving price reduction to store items",
            "- Worked on a live content push system"
        ]
    },
    {
        video: "https://www.youtube.com/embed/YQ2mYyeIPbo",
        noVideoImg: "",
        title: "Tales of Symphonia Remastered",
        position: "Software Engineer - Console Porting",
        company: "Bandai Namco",
        platforms: "(XBOX | PS | NINTENTDO SWITCH | PC)",
        topics: [
            "- Worked on upgrading renderer to DirectX 12 for XBOX",
            "- Worked on an internal tool for textures export to multiple platforms",
            "- Worked on EULA",
        ]
    },
    {
        video: "https://www.youtube.com/embed/NyCk2Vr4tEs",
        noVideoImg: "",
        title: "Logical Step",
        position: "Software Engineer",
        company: "Tedra Soft",
        platforms: "(Android | iOS)",
        topics: [
            "- Only programmer on the team",
            "- Worked on character movement and obstacles",
            "- Worked on all game menus",
            "- Worked on all gameplay features",
            "- Worked on a level editor for fast iteration and creation of levels"
        ]
    }
];

export default class Portfolio extends React.Component {
    render() {
        return (
            <section id='portfolio'>
                <h5>My Portfolio</h5>
                <h2>Professional Projects</h2>

                <div className="container portfolio__container">
                    {portfolio.map((portfolioData, id) =>
                        <article key={id} className="portfolio__item">
                            <div className="portfolio__item-video">
                                {portfolioData.video.length > 0
                                    ? <iframe width="560" height="315" src={portfolioData.video} title="YouTube video player" allowFullScreen />
                                    : <img src={test} alt="gears"></img>
                                }
                            </div>
                            <h3>{portfolioData.title}</h3>
                            <div className="portfolio__item-data">
                                <h4>{portfolioData.position}</h4>
                                <h5>{portfolioData.company}</h5>
                                <h5>{portfolioData.platforms}</h5>

                                <ul className="portfolio__item-topics">
                                    {portfolioData.topics.map((topic, topicId) =>
                                        <li key={topicId}>
                                            <small>
                                                {topic}
                                            </small>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </article>
                    )}
                </div>
            </section>
        );
    }
}