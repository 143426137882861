import React from 'react';
import './Header.css';
import CTA from './CTA.js'
import HeaderSocials from './HeaderSocials.js'

export default class Header extends React.Component {
    render() {
        return (
            <header>
                <div className="container header__container">
                    <h5>Hello I'm</h5>
                    <h1 style={{ 'color':'var(--color-red)'}}>Silviu Ilie</h1>
                    <h5 className="text-light">Founder</h5>
                    <h5 className="text-light">Coding at : <a href="https://techadise.com" target="_blank" rel="noreferrer">Techadise</a></h5>
                    <CTA />
                    <HeaderSocials/>
                </div>
            </header>    
        );
    }
}