import React, { Component } from 'react';
import Header from '../Components/Header/Header.js'
import Nav from '../Components/Nav/Nav.js'
import About from '../Components/About/About.js'
import Experience from '../Components/Experience/Experience.js'
import Services from '../Components/Services/Services.js'
import Portfolio from '../Components/Portfolio/Portfolio.js'
import Contact from '../Components/Contact/Contact.js'
import Footer from '../Components/Footer/Footer.js'
import BackgroundRender from '../Components/BackgroundRender/BackgroundRender.js'

export default class Home extends Component{
    render(){
        return(
            <div>
                <BackgroundRender />
                <Header />
                <Nav />
                <About />
                <Portfolio />
                <Experience />
                <Services />
                <Contact />
                <Footer />
            </div >
        );
    }
};