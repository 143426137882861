import React from 'react';
import './Footer.css';
import { AiFillLinkedin } from 'react-icons/ai'
import { RiYoutubeFill } from 'react-icons/ri'

export default class Footer extends React.Component {
    render() {
        return (
            <footer>
                <a href="#" className="footer__logo">Silviu Ilie</a>
            
                <ul className="permalinks">
                    <li><a href="#">Home</a></li>
                    <li><a href="#about">About</a></li>
                    <li><a href="#portfolio">Portfolio</a></li>
                    <li><a href="#experience">Experience</a></li>
                    <li><a href="#services">Services</a></li>
                    <li><a href="#contact">Contact</a></li>
                </ul>
                
                <div className="footer__socials">
                    <a href="https://www.linkedin.com/in/silviu-ilie" target="_blank" rel="noreferrer"><AiFillLinkedin /></a>
                    <a href="https://youtube.com"><RiYoutubeFill /></a>
                </div>
                
                {/* 
                <div className="footer__copyright">
                    <small>&copy;Silviu Ilie. All rights  reserved.</small>
                </div>
                */}
            </footer >
        )
    }

}