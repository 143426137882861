import React from 'react';
import './Nav.css';
import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineUser} from 'react-icons/ai'
import {BiBook} from 'react-icons/bi'
import {RiServiceLine} from 'react-icons/ri'
import { BiMessageSquareDetail } from 'react-icons/bi'
import { Link } from "react-router-dom"
import { ImBlog } from 'react-icons/im'

export default class Nav extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeNav: "#"
        };
    }

    setActiveNav(value) {
        this.setState({ activeNav: value });
    }

    render() {
        return (
            <nav>
                <a href="#" onClick={() => this.setActiveNav("#")} className={this.state.activeNav === "#" ? 'active' : ''}><AiOutlineHome /></a>
                <a href="#about" onClick={() => this.setActiveNav("#about")} className={this.state.activeNav === "#about" ? 'active' : ''} ><AiOutlineUser /></a>
                <a href="#experience" onClick={() => this.setActiveNav("#experience")} className={this.state.activeNav === "#experience" ? 'active' : ''}><BiBook/></a>
                <a href="#services" onClick={() => this.setActiveNav("#services")} className={this.state.activeNav === "#services" ? 'active' : ''}><RiServiceLine/></a>
                <a href="#contact" onClick={() => this.setActiveNav("#contact")} className={this.state.activeNav === "#contact" ? 'active' : ''}><BiMessageSquareDetail /></a>
            </nav>
        );
    }
}