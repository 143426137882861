import React from 'react';
import './BackgroundRender.css'
import { vertexShaderSource, fragmentShaderSource, createShader } from './Shaders';
import { createProgram } from './RendererHelpers'

var canvas = null;
var gl = null;
var program = null;
var positionAttributeLocation = null;
var resolutionLocation = null;
var mouseLocation = null;
var timeLocation = null;
var positionBuffer = null;
var time = 0;

function onResizeWindow() {
    if (gl != null) {
        if (window.innerWidth > 1280) {
            canvas.width = 1280;
            canvas.height = 720;
        }
        else {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        }
        gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    }
}

async function draw() {
    while (true) {
        await new Promise(resolve => setTimeout(resolve, 33));
        time += 0.033;

        //clear color with the same color as the site background
        gl.clearColor(0.0117, 0.0745, 0.16, 1.0);
        gl.clear(gl.COLOR_BUFFER_BIT);

        gl.useProgram(program);
        gl.enableVertexAttribArray(positionAttributeLocation);

        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

        //tell the attribute how to get data out of position buffer)
        var size = 2;
        var type = gl.FLOAT;
        var normalize = false;
        var stride = 0;
        var offset = 0;
        gl.vertexAttribPointer(positionAttributeLocation, size, type, normalize, stride, offset);

        gl.uniform2f(resolutionLocation, gl.canvas.width, gl.canvas.height);
        gl.uniform1f(timeLocation, time);

        //draw
        var primitiveType = gl.TRIANGLES;
        var offset = 0;
        var count = 6;
        gl.drawArrays(primitiveType, offset, count);
    }
}

function main() {
    canvas = document.querySelector("#glCanvas");

    //if (window.pageYOffset > 0) {
    //    var offset = canvas.offsetTop;
    //    var opac = (window.pageYOffset / offset);
    //    document.body.style.background =
    //        "linear-gradient(rgba(255, 255, 255, " + opac + "), rgba(255, 255, 255, " + opac + ")), url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/times-square-perspective.jpg) no-repeat";
    //
    //}

    gl = canvas.getContext("webgl");

    if (gl === null) {
        alert("Unable to initialize WebGL. Your browser or machine may not support it.");
        return;
    }

    onResizeWindow();

    var vs = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
    var fs = createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);
    program = createProgram(gl, vs, fs);

    positionAttributeLocation = gl.getAttribLocation(program, "a_position");
    resolutionLocation = gl.getUniformLocation(program, "iResolution");
    mouseLocation = gl.getUniformLocation(program, "iMouse");
    timeLocation = gl.getUniformLocation(program, "iTime");

    positionBuffer = gl.createBuffer();

    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

    var positions = [
        -1, -1,
        1, -1,
        -1, 1,
        -1, 1,
        1, -1,
        1, 1
    ];

    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

    //canvas.addEventListener('mousemove', setMousePosition);
    //canvas.addEventListener('touchstart', (e) => {
    //    e.preventDefault();
    //}, { passive: false });
    //canvas.addEventListener('touchmove', (e) => {
    //    e.preventDefault();
    //    setMousePosition(e.touches[0]);
    //}, { passive: false });

    draw();
}

export default class BackgroundRender extends React.Component {

    componentDidMount() {
        window.addEventListener("resize", onResizeWindow);
        main();
    }

    render() {
        return (
            <div className="backgroundRender">
                <canvas id="glCanvas"/>
            </div>
        )
    }
}