import React, { Component } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Link } from "react-router-dom"

import { vscDarkPlus as CodeStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './BlogPages.css'
import NavBlogs from '../../Components/Nav/NavBlogs.js'
import Contact from '../../Components/Contact/Contact.js'
import Footer from '../../Components/Footer/Footer.js'
import { BlogEntries } from '../../Components/BlogsOverview/BlogEntries.js'

export default class UnrealMovementP1 extends Component {
    render() {
        const playerClassExample = 
        `class Player\n{\n// Constructors and other code\nprivate:\n\tfloat m_health;\n\tfloat m_mana;\n\tfloat m_power;\n\tVector3* m_meshVertices;\n\tAABB m_meshBounds;\n\tVector3 m_position;\n\tVector3 m_rotation;\n\tVector3 m_velocity\n\t\n};`;

        const statsClassExample =
            `class StatsComponent\n{\n// Constructors and other code\nprivate:\n\tfloat m_health;\n\tfloat m_mana;\n\tfloat m_power;\n};`;

        const playersClassComponentsExample =
            `class Player\n{\n// Constructors and other code\nprivate:\n\tMeshComponent m_meshComponent;\n\tStatsComponent m_statsComponent;\n\tInputComponent m_inputComponent;\n\tMovementComponent m_movementComponent;\n};`;

        return (
            <div>
                <section>
                    <h2>{BlogEntries[0].title}</h2>
                    <div className="content-background container">
                        <h3>1. Introduction</h3>
                        <p>Even if Unreal Engine is one of the biggest game engines on the market, the documentation is not always great, especially when talking about C++.</p>
                        <br/>
                        <p>One of the most important topics that is not well documented is how movement works in Unreal.</p>
                        <p>This is the first entry in a series that aims to demystify how the movement code works.
                            It is an introduction into Actor Components and Scene Components. For everyone familiar with the gameplay framework,
                            they can skip to the <Link to={BlogEntries[1].link}>next blog entry</Link> in this series.</p>
                        <br />
                        <h3>2. Composition in programming</h3>
                        <p>
                            One of the core concepts of OOP is <b>composition</b>, which is the idea of creating a complex class using smaller ones.
                        </p>
                        <p> In order to better understand how composition works, we can look over a simple C++ example: </p>
                        <SyntaxHighlighter language="cpp" style={CodeStyle}>
                            {playerClassExample}
                        </SyntaxHighlighter>
                        <p> Even in this simple example that contains only variables, we can see that there are variables that have nothing to do with each other(e.g. mesh vertices and mana points).
                            Writing code this way might work for smaller games, but it gets messy really fast. Think about adding input, UI, audio and other functionality directly to the player class.
                        </p><br/>
                        <p>Composition can help us in this use case. We can create classes like the next one:</p>
                        <SyntaxHighlighter language="cpp" style={CodeStyle}>
                            {statsClassExample}
                        </SyntaxHighlighter>
                        <p>Now, our player class can actually only be a container of these components.</p>
                        <SyntaxHighlighter language="cpp" style={CodeStyle}>
                            {playersClassComponentsExample}
                        </SyntaxHighlighter>
                        <p>Even if the example looks only over variables, we can actually also encapsulate the logic inside these classes. For example, we can add code to make sure health does not go
                            below 0 or bigger than maximum health inside of the stats component, we can make sure that input is handled by the input component, and nothing goes through the player.
                        </p>
                        <br/>
                        <p>Our code is now more modular and, in addition, more flexible. Now, if we are creating a dog class, we can add instances of the StatsComponent, MeshComponent and also Movement Component to it too.
                        If we are creating a weapon class, we can use the MeshComponent for it too. </p>
                        <br />
                        <h3>3. Actor Components in Unreal Engine</h3>
                        <p>
                            Composition in Unreal Engine is done using Actor Components. A big part of the gameplay framework is done using actor components. As in the examples above, Unreal also has components
                            for multiple important parts of the gameplay. The <b>Movement Component</b> is a Actor Component.
                        </p>
                        <br />
                        <h3>4. Scene Components in Unreal Engine</h3>
                        <p>
                            Scene Components are actually Actor Components, that also have a location inside the world. 
                            Even if they have a world location, it is not mandatory that they are also rendering geometry. 
                            For example, some of the most important parts of a game, Camera and Audio are represented by Scene Components but they do not have any geometry.
                            On the other hand, Scene Components are also responsible for drawing static meshes and skeletal meshes on the screen.
                        </p>
                        <br />
                        <br />
                        <h2></h2>
                        <p>In the <Link to={BlogEntries[1].link}>next blog entry</Link>, we will start looking over Movement Components architecture.</p>

                    </div>
                </section>
                <NavBlogs />
                <Contact />
                <Footer />
            </div >
        );
    }
};